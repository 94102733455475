// src/components/Products/SeasonalBanner.js
import React from 'react';
import '../../styles/Products/SeasonalBanner.css';
import bannerImage from '../../assets/images/halloweenbanner.png'; // Ensure the path is correct
import splitTextIntoSpans from '../../utils/splitText'; // Import the helper function

const SeasonalBanner = () => {
  return (
    <div className="seasonal-banner">

      <div className="banner-text">
            <h2>{splitTextIntoSpans('Warmer weather and brighter vibes!')}</h2>
        <p>Spring is here! Enjoy some of our delectable treats under the sun! .</p>
      </div>
    </div>
  );
};

export default SeasonalBanner;