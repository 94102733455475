// src/components/Navbar.js
import React, { useState, useContext, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css'; // Import CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from './context/CartContext';

const Navbar = ({ toggleCart }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMeetTheBakerOpen, setIsMeetTheBakerOpen] = useState(false); // New state for dropdown
  const { cartItems } = useContext(CartContext);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  const navbarRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // Observe in relation to the viewport
      threshold: 0, // Trigger callback when even a single pixel is visible
    };

    const navbarObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsNavbarVisible(entry.isIntersecting);
      });
    }, observerOptions);

    if (navbarRef.current) {
      navbarObserver.observe(navbarRef.current);
    }

    return () => {
      if (navbarRef.current) {
        navbarObserver.unobserve(navbarRef.current);
      }
    };
  }, []);

  // Calculate total items in the cart
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to toggle the dropdown menu
  const toggleDropdown = () => {
    setIsMeetTheBakerOpen(!isMeetTheBakerOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMeetTheBakerOpen &&
        !event.target.closest('.navbar-dropdown') &&
        !event.target.closest('.dropdown-toggle')
      ) {
        setIsMeetTheBakerOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMeetTheBakerOpen]);

  return (
    <>
      <header ref={navbarRef} className="navbar text-beige">
        <nav className="navbar-container font-evenings">
          {/* Logo with the image */}
          <div className="navbar-logo">
            <img
              src={require('../assets/images/DEYDEYBAKESLOGO.png')}
              alt="Dey Dey Bakes Logo"
              className="logo-image"
            />
          </div>

          {/* Desktop Navigation Links */}
          <ul className={`navbar-links ${isOpen ? 'navbar-open' : ''}`}>
            <li>
              <NavLink exact="true" to="/" activeclassname="active" onClick={() => setIsOpen(false)}>
                Home
              </NavLink>
            </li>
            <li className="navbar-dropdown">
              <button
                className="dropdown-toggle"
                onClick={toggleDropdown}
                aria-expanded={isMeetTheBakerOpen ? 'true' : 'false'}
              >
                Meet The Baker 
              </button>
              <ul className={`dropdown-menu ${isMeetTheBakerOpen ? 'show' : ''}`}>
                <li>
                  <NavLink
                    to="/meetthebaker"
                    activeclassname="active"
                    onClick={() => {
                      setIsOpen(false);
                      setIsMeetTheBakerOpen(false);
                    }}
                  >
                    Meet The Baker
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/ourcreations"
                    activeclassname="active"
                    onClick={() => {
                      setIsOpen(false);
                      setIsMeetTheBakerOpen(false);
                    }}
                  >
                    Our Creations
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/products" activeclassname="active" onClick={() => setIsOpen(false)}>
                Products
              </NavLink>
            </li>
            <li>
              <NavLink to="/faqs" activeclassname="active" onClick={() => setIsOpen(false)}>
                FAQS
              </NavLink>
            </li>
          </ul>

          {/* Mobile Menu (Hamburger) */}
          <button className="navbar-hamburger" onClick={toggleMenu}>
            ☰
          </button>

          {/* Cart Icon */}
          <div className="cart-icon" onClick={toggleCart} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faShoppingCart} size="lg" />
            {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
          </div>
        </nav>
      </header>

      {/* Floating Cart Icon */}
      {!isNavbarVisible && (
        <div className="floating-cart-icon" onClick={toggleCart} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faShoppingCart} size="2x" />
          {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
        </div>
      )}
    </>
  );
};

export default Navbar;
